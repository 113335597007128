import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
//import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //private URL = 'http://localhost:8000';
  private URL = environment.URLAPI;
  constructor(
    private http: HttpClient
   ) { }

  singin(user:any){
      return this.http.post(`${this.URL}/loginticket`,user);
  }

  isAuth():boolean{
    //const token = localStorage.getItem('token');
    /*
    if(this.jwtHelper.isTokenExpired(token) || !localStorage.getItem('token')){
      return false;
    }
    */
    //localStorage.setItem('tokenlaboratorio', JSON.stringify(result));
    if(!localStorage.getItem('tokenlaboratorio'))
      return false;

    return true;
  }

}
