import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)},
  {path: 'members', loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard]},
  {path: 'laboratorio', loadChildren: () => import('./modules/laboratorio/laboratorio.module').then(m => m.LaboratorioModule), canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
